<div class="page-title-area-jobs">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text noob">
                    <h2>Find A Job</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Find A Job</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <section class="job-area job-area-two" style="padding-top:30px">
        <div class="row">
            <div class="col-12" style="display: flex;justify-content: center; padding: 20px;">
                <button class="btn btn-primary filter btn" type="button" data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                    Filter
                </button>
            </div>
            <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <div class="offcanvas-header">
                    <h5 class="offcanvas-title" id="offcanvasExampleLabel">Filter Data</h5>
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                        aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <div class="col-lg-12 ">
                        <div class="job-item1">
                            <div class="collection-collapse-block border-0" [class.open]="collapse">
                                <h3 class="collapse-block-title" (click)="collapse = !collapse"
                                    style="margin-bottom:3% ; margin-left: 3%;">Industry</h3>
                                <div class="collection-collapse-block-content">
                                    <div class="scroll scroll--grey">
                                        <ul class="category-list">
                                            <li *ngFor="let p of getDepartment">
                                                <a style="cursor: pointer; color: black;"
                                                    (click)="getSelectedJobList(p.department_name,1)">
                                                    {{p.department_name}}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="job-item1">
                            <div class="collection-collapse-block border-0" [class.open]="collapse6">
                                <h3 class="collapse-block-title" (click)="collapse6 = !collapse6"
                                    style="margin-bottom:3% ; margin-left: 3%;"> Functional Area</h3>
                                <div class="collection-collapse-block-content">
                                    <div class="scroll scroll--grey">
                                        <ul class="category-list">
                                            <li *ngFor="let p of industry">
                                                <a style="cursor: pointer; color: black;"
                                                    (click)="getSelectedJobList(p.function_name,5)">
                                                    {{p.function_name}}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="job-item1">
                            <div class="collection-collapse-block border-0" [class.open]="collapse1">
                                <h3 class="collapse-block-title" (click)="collapse1 = !collapse1"
                                    style="margin-bottom:3% ; margin-left: 3%;">Location</h3>
                                <div class="collection-collapse-block-content">
                                    <div class="scroll scroll--grey">
                                        <ul class="category-list">
                                            <li *ngFor="let q of getLocation">
                                                <a style="cursor: pointer; color: black;"
                                                    (click)="getSelectedJobList(q.location_name,2)">
                                                    {{q.location_name}}
                                                </a>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="job-item1">
                            <h5>EDUCATION</h5>
                            <div class="collection-collapse-block border-0" [class.open]="collapse3">
                                <h3 class="collapse-block-title" (click)="collapse3 = !collapse3"
                                    style="margin-bottom:3% ; margin-left:3%;">Graduate</h3>
                                <div class="collection-collapse-block-content">
                                    <div class="scroll scroll--grey">
                                        <ul class="category-list">
                                            <li *ngFor="let r of qualification">
                                                <a style="cursor: pointer;color: black;"
                                                    (click)="getSelectedJobList(r.qualification,3)">
                                                    {{r.qualification}}
                                                </a>
                                            </li>
                                        </ul>
                                        <!-- </div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="collection-collapse-block border-0" [class.open]="collapse4">
                                <h3 class="collapse-block-title" (click)="collapse4 = !collapse4"
                                    style="margin-bottom:3% ; margin-left:3%;">Post graduate</h3>
                                <div class="collection-collapse-block-content">
                                    <div class="scroll scroll--grey">
                                        <ul class="category-list">
                                            <li *ngFor="let r of postedu">
                                                <a style="cursor: pointer;color: black;"
                                                    (click)="getSelectedJobList(r.name,6)">
                                                    {{r.name}}
                                                </a>
                                            </li>
                                        </ul>
                                        <!-- </div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="collection-collapse-block border-0" [class.open]="collapse5">
                                <h3 class="collapse-block-title" (click)="collapse5 = !collapse5"
                                    style="margin-bottom:3% ; margin-left:3%;">Doctoral/Ph.d</h3>
                                <div class="collection-collapse-block-content">
                                    <div class="scroll scroll--grey">
                                        <ul class="category-list">
                                            <li *ngFor="let r of phdedu">
                                                <a style="cursor: pointer;color: black;"
                                                    (click)="getSelectedJobList(r.name,7)">
                                                    {{r.name}}
                                                </a>
                                            </li>
                                        </ul>
                                        <!-- </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="job-item1">
                            <div class="collection-collapse-block border-0" [class.open]="collapse2">
                                <h3 class="collapse-block-title" (click)="collapse2 = !collapse2"
                                    style="margin-bottom:3% ; margin-left: 3%;">Salary</h3>
                                <!-- {{this.globaljoblist[0].from_salary}} -->
                                <div class="collection-collapse-block-content">
                                    <!-- <ngx-slider [(value)]="value" [(highValue)]="maxvalue" [options]="options" (click)="getsalrydata(value,maxvalue)"></ngx-slider> -->
                                    <!-- </div> -->
                                    <div class="scroll scroll--grey">
                                        <ul class="category-list">
                                            <div class="gender-area">
                                                <!-- </div> -->
                                            </div>
                                            <li *ngFor="let s of salary">
                                                <a style="cursor: pointer;color: black;"
                                                    (click)="getSelectedJobList(s.salary_range,8)">
                                                    {{s.salary_range}}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="job-item1">
                            <div class="collection-collapse-block border-0" [class.open]="collapse4">
                                <h3 class="collapse-block-title" (click)="collapse4 = !collapse4"
                                    style="margin-bottom:3% ; margin-left: 3%;">Experience (years)</h3>
                                <div class="collection-collapse-block-content">
                                    <ngx-slider [(value)]="value1" [options]="option"
                                        (click)="getexperiencedata(value1)">
                                    </ngx-slider>
                                    <!-- </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 close">
                <div class="job-item1">
                    <div class="collection-collapse-block border-0" [class.open]="collapse">
                        <h3 class="collapse-block-title" (click)="collapse = !collapse"
                            style="margin-bottom:3% ; margin-left: 3%;">Industry</h3>
                        <div class="collection-collapse-block-content">
                            <div class="scroll scroll--grey">
                                <ul class="category-list">
                                    <li *ngFor="let p of getDepartment">
                                        <a style="cursor: pointer; color: black;"
                                            (click)="getSelectedJobList(p.department_name,1)">
                                            {{p.department_name}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="job-item1">
                    <div class="collection-collapse-block border-0" [class.open]="collapse6">
                        <h3 class="collapse-block-title" (click)="collapse6 = !collapse6"
                            style="margin-bottom:3% ; margin-left: 3%;"> Functional Area</h3>
                        <div class="collection-collapse-block-content">
                            <div class="scroll scroll--grey">
                                <ul class="category-list">
                                    <li *ngFor="let p of industry">
                                        <a style="cursor: pointer; color: black;"
                                            (click)="getSelectedJobList(p.function_name,5)">
                                            {{p.function_name}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>



                <div class="job-item1">
                    <div class="collection-collapse-block border-0" [class.open]="collapse1">
                        <h3 class="collapse-block-title" (click)="collapse1 = !collapse1"
                            style="margin-bottom:3% ; margin-left: 3%;">Location</h3>
                        <div class="collection-collapse-block-content">
                            <div class="scroll scroll--grey">
                                <ul class="category-list">
                                    <li *ngFor="let q of getLocation">
                                        <a style="cursor: pointer; color: black;"
                                            (click)="getSelectedJobList(q.location_name,2)">
                                            {{q.location_name}}
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="job-item1">
                    <h5>EDUCATION</h5>
                    <div class="collection-collapse-block border-0" [class.open]="collapse3">
                        <h3 class="collapse-block-title" (click)="collapse3 = !collapse3"
                            style="margin-bottom:3% ; margin-left:3%;">Graduate</h3>
                        <div class="collection-collapse-block-content">
                            <div class="scroll scroll--grey">
                                <ul class="category-list">
                                    <li *ngFor="let r of qualification">
                                        <a style="cursor: pointer;color: black;"
                                            (click)="getSelectedJobList(r.qualification,3)">
                                            {{r.qualification}}
                                        </a>
                                    </li>
                                </ul>
                                <!-- </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="collection-collapse-block border-0" [class.open]="collapse4">
                        <h3 class="collapse-block-title" (click)="collapse4 = !collapse4"
                            style="margin-bottom:3% ; margin-left:3%;">Post graduate</h3>
                        <div class="collection-collapse-block-content">
                            <div class="scroll scroll--grey">
                                <ul class="category-list">
                                    <li *ngFor="let r of postedu">
                                        <a style="cursor: pointer;color: black;" (click)="getSelectedJobList(r.name,6)">
                                            {{r.name}}
                                        </a>
                                    </li>
                                </ul>
                                <!-- </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="collection-collapse-block border-0" [class.open]="collapse5">
                        <h3 class="collapse-block-title" (click)="collapse5 = !collapse5"
                            style="margin-bottom:3% ; margin-left:3%;">Doctoral/Ph.d</h3>
                        <div class="collection-collapse-block-content">
                            <div class="scroll scroll--grey">
                                <ul class="category-list">
                                    <li *ngFor="let r of phdedu">
                                        <a style="cursor: pointer;color: black;" (click)="getSelectedJobList(r.name,7)">
                                            {{r.name}}
                                        </a>
                                    </li>
                                </ul>
                                <!-- </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="job-item1">
                    <div class="collection-collapse-block border-0" [class.open]="collapse2">
                        <h3 class="collapse-block-title" (click)="collapse2 = !collapse2"
                            style="margin-bottom:3% ; margin-left: 3%;">Salary</h3>
                        <!-- {{this.globaljoblist[0].from_salary}} -->
                        <div class="collection-collapse-block-content">
                            <!-- <ngx-slider [(value)]="value" [(highValue)]="maxvalue" [options]="options" (click)="getsalrydata(value,maxvalue)"></ngx-slider> -->
                            <!-- </div> -->
                            <div class="scroll scroll--grey">
                                <ul class="category-list">
                                    <div class="gender-area">
                                        <!-- </div> -->
                                    </div>
                                    <li *ngFor="let s of salary">
                                        <a style="cursor: pointer;color: black;"
                                            (click)="getSelectedJobList(s.salary_range,8)">
                                            {{s.salary_range}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="job-item1">
                    <div class="collection-collapse-block border-0" [class.open]="collapse4">
                        <h3 class="collapse-block-title" (click)="collapse4 = !collapse4"
                            style="margin-bottom:3% ; margin-left: 3%;">Experience (years)</h3>
                        <div class="collection-collapse-block-content">
                            <ngx-slider [(value)]="value1" [options]="option" (click)="getexperiencedata(value1)">
                            </ngx-slider>
                            <!-- </div> -->
                        </div>
                    </div>
                </div>
            </div>



            <div class="col-lg-8 ">
                <!-- {{joblist.length}} -->
                <form [formGroup]="jobassignpostform">
                    <!-- <div *ngFor="let i of joblist">
                        <div class="job-item">
                            <img src="{{i.img_url}}" alt="img" style="width:60px; height: 60px;"
                                onerror="this.src='assets/img/server-job.svg';">
                            <div class="job-inner align-items-center">
                                <div class="job-inner-left" style="margin-top: 3%;">
                                    <h3 style="font-size:17px;text-transform: capitalize;">{{i.company_name}}</h3>
                                    <ul>
                                        <li> <label>Course Fee:</label><i class="icofont-money-bag"></i>{{i.salary}}</li>
                                        <label>Experience : </label><span>{{i.experience}}years</span>&nbsp;
                                        <span><i class="icofont-star"> {{i.rating}}</i></span>&nbsp;
                                        <label>Qualification:</label><span> {{i.qualification_name}}</span>&nbsp;
                                        <i class="fa fa-building-o" aria-hidden="true">&nbsp;&nbsp;
                                            {{i.department_name}}</i>
                                        <i class="icofont-location-pin"> {{i.location_name}}</i>&nbsp;
                                        <br>
                                    </ul>
                                </div>
                                <div class="job-inner-right">
                                    <ul>
                                        <li><a (click)="jobassignstudent(i)">Apply</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div *ngFor="let job of joblist" class="job-item">
                        <img [src]="job.img_url" alt="img" onerror="this.src='assets/img/server-job.svg';"
                            class="job-image" />
                        <div class="job-inner align-items-center">
                            <div class="job-inner-left">
                                <h3 class="company-name">{{ job.company_name }}</h3>
                                <ul class="job-details">
                                    <li>
                                        <label>Course Fee:</label>
                                        <i class="icofont-money-bag"></i>{{ job.salary }}
                                    </li>
                                    <li>
                                        <label>Experience:</label>
                                        <span>{{ job.experience }} years</span>
                                    </li>
                                    <li>
                                        <span><i class="icofont-star">{{ job.rating }}</i></span>
                                    </li>
                                    <li>
                                        <label>Qualification:</label>
                                        <span>{{ job.qualification_name }}</span>
                                    </li>
                                    <li>
                                        <i class="fa fa-building-o" aria-hidden="true"></i>
                                        {{ job.department_name }}
                                    </li>
                                    <li>
                                        <i class="icofont-location-pin"></i>
                                        {{ job.location_name }}
                                    </li>
                                </ul>
                            </div>
                            <div class="job-inner-right">
                                <ul>
                                    <li>
                                        <a (click)="jobassignstudent(job)">Apply</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </form>
                <div *ngIf="joblist.length == 0">
                    <h4 class="text">No Result Found</h4>
                </div>
            </div>
        </div>
        <!-- </div> -->
    </section>
</div>