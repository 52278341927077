<div class="page-title-area-jobs">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text noob">
                    <h2>package</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>package</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="pricing-area">
    <div class="container">
        <form [formGroup]="addjobpackageForm">

            <div class="row">
                <div class="col-sm-6 col-lg-6">
                    <h2 style="font-size:18px ;">Recruiter Details</h2>

                    <div class="card user-card-full" *ngFor="let item of companyarr">
                        <div class="row m-l-0 m-r-0">
                            <div class="col-sm-4 bg-c-lite-green user-profile">
                                <div class="card-block text-center text-white">
                                    <div class="m-b-25">
                                        <img src="{{item.file}}" class="img-radius " alt="User-Profile-Image">
                                    </div>
                                    <h6 class="f-w-600">{{item.company_name}}</h6>
                                    <p>{{item.industry_type}}</p>
                                    <i class=" mdi mdi-square-edit-outline feather icon-edit m-t-10 f-16"></i>
                                </div>
                            </div>
                            <div class="col-sm-8" style="    background: #e2ffef;">
                                <div class="card-block">
                                    <h6 class="m-b-20 p-b-5 b-b-default f-w-600">Information</h6>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <p class="m-b-10 f-w-600">Email</p>
                                            <h6 class="text-muted f-w-400"> {{item.recruiter_email}} </h6>
                                        </div>
                                        <div class="col-sm-6">
                                            <p class="m-b-10 f-w-600">Phone</p>
                                            <h6 class="text-muted f-w-400">{{item.phone_number}}</h6>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <p class="m-b-10 f-w-600">Address</p>
                                            <h6 class="text-muted f-w-400"> {{item.address}} </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                    <h2 style="font-size:18px ;">Recruiter Payment Details</h2>
                    <div class="card user-card-full">
                        <div class="row m-l-0 m-r-0">
                            <div class="col-sm-12" style="    background: #e2ffef;">
                                <div class="card-block">
                                    <h6 class="m-b-20 p-b-5 b-b-default f-w-600">Package Information</h6>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <p class="m-b-10 f-w-600">Package Name</p>
                                            <h6 class="text-muted f-w-400"> {{packagedatails.packagename}} </h6>
                                        </div>
                                        <div class="col-sm-6">
                                            <p class="m-b-10 f-w-600">Package Amount</p>
                                            <h6 class="text-muted f-w-400">{{packagedatails.packageamount}}</h6>
                                        </div>

                                        <div class="col-sm-6">
                                            <p class="m-b-10 f-w-600">How Many Resumes:</p>
                                            <h6 class="text-muted f-w-400">{{packagedatails.uploadresume}}</h6>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12">
                                        </div>
                                        <div class="col-sm-12">
                                            <p class="m-b-10 f-w-600">Description</p>
                                            <h6 class="text-muted f-w-400"> {{packagedatails.description}} </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style="margin-bottom: 10%;">
                <div class="job-item">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="mb-3 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <label class="form-label">Job Title</label>
                                            <select formControlName="job_title" *ngIf="getDepartment.length"
                                                class="form-control">
                                                <option value="" disabled selected>Choose Job Title</option>
                                                <option *ngFor="let p of getDepartment" [value]="p.department_name">
                                                    {{p.department_name}}
                                                </option>
                                            </select>
                                            <div *ngIf="submitted && addUsersFormCnt['salary'].errors"
                                                class="invalid-feedback">
                                                <span *ngIf="addUsersFormCnt['salary'].errors.required">Please Enter
                                                    Salary.</span>
                                            </div>
                                        </div>
                                        <div class="mb-3 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <label class="form-label">Salary</label>
                                            <input type="text" class="form-control" formControlName="salary"
                                                (keypress)="numericOnly($event)" placeholder="Enter Salary" />
                                            <div *ngIf="submitted && addUsersFormCnt['salary'].errors"
                                                class="invalid-feedback">
                                                <span *ngIf="addUsersFormCnt['salary'].errors.required">Please Enter
                                                    Salary.</span>
                                            </div>
                                        </div>
                                        <div class="mb-3 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <label class="form-label">Experience</label>
                                            <input type="text" class="form-control" formControlName="experience"
                                                (keypress)="numericOnly($event)" placeholder="Enter Your Experience" />
                                            <div *ngIf="submitted && addUsersFormCnt['experience'].errors"
                                                class="invalid-feedback">
                                                <span *ngIf="addUsersFormCnt['experience'].errors.required">Please Enter
                                                    Experience.</span>
                                            </div>
                                        </div>
                                        <div class="mb-3 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <label class="form-label">Remarks</label>
                                            <textarea cols="30" rows="3" class="form-control form-control-rounded"
                                                formControlName="description"></textarea>
                                            <div *ngIf="submitted && addUsersFormCnt['description'].errors"
                                                class="invalid-feedback">
                                                <span *ngIf="addUsersFormCnt['description'].errors.required">Please
                                                    Enter
                                                    Remarks </span>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-12 mb-3">
                                            <div class="form-group">
                                                <label for="input-13">Job Type</label>
                                                <ng-select formControlName="join_type">
                                                    <ng-option value="1">
                                                        Full Time</ng-option>
                                                    <ng-option value="2">
                                                        Part Time</ng-option>
                                                    <ng-option value="3">
                                                        Walkins</ng-option>
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class=" col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div class="form-group">
                                                <label for="input-13">Location</label>
                                                <select formControlName="location_name" class="form-control"
                                                    *ngIf="Locations.length" (change)="getlocation($event)">
                                                    <option *ngFor="let item of Locations"
                                                        [value]="[item.location_name,item.id]">
                                                        {{item.location_name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <form [formGroup]="departmentform" (ngSubmit)="submitdepartmentdata()">
                                                <div class="row">
                                                    <div class="col-9 col-lg-9 col-xl-9">
                                                        <div class="form-group">
                                                            <label for="input-14"
                                                                style="margin-top: 2%;">Industry</label>
                                                            <select formControlName="department_name"
                                                                class="form-control" *ngIf="departments.length">
                                                                <option *ngFor="let item of departments"
                                                                    value="{{item.department_name}},{{item.id}}">
                                                                    {{item.department_name}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-3 col-lg-3 col-xl-3" style="margin-top: 7%;">
                                                        <button type="submit"
                                                            class="btn btn-primary">&nbsp;&nbsp;&nbsp;Add&nbsp;&nbsp;&nbsp;</button>
                                                    </div>
                                                </div>
                                            </form>
                                            <div class="card">
                                                <div class="card-body">
                                                    <table class="table table-image">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">S.No</th>
                                                                <th scope="col">Department</th>
                                                                <th scope="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let p of selected ; let i= index;">
                                                                <td>&nbsp;&nbsp;&nbsp;&nbsp;{{i+1}}</td>
                                                                <td> {{p.department_name}} </td>

                                                                <td>
                                                                    <i class="fa fa-trash"
                                                                        (click)="deletedepartment(p.department_name)"></i>
                                                                </td>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <form [formGroup]="functionform" (ngSubmit)="submitfunctiondata()">
                                                <div class="row">
                                                    <div class="col-9 col-lg-9 col-xl-9">
                                                        <div class="form-group">
                                                            <label for="input-14" style="margin-top: 2%;">Functional
                                                                Area</label>
                                                            <ng-select formControlName="function_name">
                                                                <ng-option *ngFor="let item of functiondata"
                                                                    [value]="{name:item.function_name, id:item.id}">
                                                                    {{item.function_name}}</ng-option>
                                                            </ng-select>
                                                        </div>
                                                    </div>
                                                    <div class="col-3 col-lg-3 col-xl-3" style="margin-top: 7%;">
                                                        <button type="submit"
                                                            class="btn btn-primary">&nbsp;&nbsp;&nbsp;Add&nbsp;&nbsp;&nbsp;</button>
                                                    </div>
                                                </div>
                                            </form>
                                            <div class="card">
                                                <div class="card-body">
                                                    <table class="table table-image">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">S.No</th>
                                                                <th scope="col">Department</th>
                                                                <th scope="col">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let p of functionData ; let i= index;">
                                                                <td>&nbsp;&nbsp;&nbsp;&nbsp;{{i+1}}</td>
                                                                <td> {{p.function_name}} </td>

                                                                <td>
                                                                    <i class="fa fa-trash"
                                                                        (click)="deletedfunction(p.function_name)"></i>
                                                                </td>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <form [formGroup]="addjobEducationForm" (ngSubmit)="addjobEducation()">
                                                <div class="row">
                                                    <div class="col-9 col-lg-9 col-xl-9">
                                                        <div class="form-group">
                                                            <label for="input-15"
                                                                style="margin-top: 2%;">Graduation</label>

                                                            <ng-select formControlName="Education_name"
                                                                style="width: 100%;" *ngIf="Educations.length">
                                                                <ng-option *ngFor="let item of Educations"
                                                                    value="{{item.qualification}},{{item.id}}">
                                                                    {{item.qualification}}</ng-option>

                                                            </ng-select>
                                                        </div>
                                                    </div>
                                                    <div class="col-3 col-lg-3 col-xl-3" style="margin-top: 7%;">
                                                        <button type="submit"
                                                            class="btn btn-primary">&nbsp;&nbsp;&nbsp;Add&nbsp;&nbsp;&nbsp;</button>
                                                    </div>

                                                </div>

                                            </form>
                                            <div class="card">
                                                <div class="card-body">
                                                    <table class="table table-image">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">S.No</th>
                                                                <th scope="col">Education</th>
                                                                <th scope="col">Action</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let p of Education ; let i= index;">
                                                                <td>&nbsp;&nbsp;&nbsp;&nbsp;{{i+1}}</td>
                                                                <td> {{p.Education_name}} </td>

                                                                <td>
                                                                    <i class="fa fa-trash"
                                                                        (click)="deleteEducation(p.Education_name)"></i>
                                                                </td>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <form [formGroup]="addjobpostEducationForm"
                                                (ngSubmit)="addjobpostEducation()">
                                                <div class="row">
                                                    <div class="col-9 col-lg-9 col-xl-9">
                                                        <div class="form-group">
                                                            <label for="input-15" style="margin-top: 2%;">Post
                                                                Graduation</label>

                                                            <ng-select formControlName="name" style="width: 100%;">
                                                                <ng-option *ngFor="let item of postEducations"
                                                                    value="{{item.name}},{{item.id}}">
                                                                    {{item.name}}</ng-option>

                                                            </ng-select>
                                                        </div>
                                                    </div>
                                                    <div class="col-3 col-lg-3 col-xl-3" style="margin-top: 7%;">
                                                        <button type="submit"
                                                            class="btn btn-primary">&nbsp;&nbsp;&nbsp;Add&nbsp;&nbsp;&nbsp;</button>
                                                    </div>

                                                </div>

                                            </form>
                                            <div class="card">
                                                <div class="card-body">
                                                    <table class="table table-image">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">S.No</th>
                                                                <th scope="col">Post Graduation</th>
                                                                <th scope="col">Action</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let p of PostEducation ; let i= index;">
                                                                <td>&nbsp;&nbsp;&nbsp;&nbsp;{{i+1}}</td>
                                                                <td> {{p.name}} </td>

                                                                <td>
                                                                    <i class="fa fa-trash"
                                                                        (click)="deletepostEducation(p.name)"></i>
                                                                </td>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>




                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <form [formGroup]="addjobphdEducationForm"
                                                (ngSubmit)="addjobphdEducation()">
                                                <div class="row">
                                                    <div class="col-9 col-lg-9 col-xl-9">
                                                        <div class="form-group">
                                                            <label for="input-15" style="margin-top: 2%;">Phd
                                                                Graduation</label>

                                                            <ng-select formControlName="name" style="width: 100%;">
                                                                <ng-option *ngFor="let item of phdEducations"
                                                                    value="{{item.name}},{{item.id}}">
                                                                    {{item.name}}</ng-option>

                                                            </ng-select>
                                                        </div>
                                                    </div>
                                                    <div class="col-3 col-lg-3 col-xl-3" style="margin-top: 7%;">
                                                        <button type="submit"
                                                            class="btn btn-primary">&nbsp;&nbsp;&nbsp;Add&nbsp;&nbsp;&nbsp;</button>
                                                    </div>

                                                </div>

                                            </form>
                                            <div class="card">
                                                <div class="card-body">
                                                    <table class="table table-image">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">S.No</th>
                                                                <th scope="col">Phd Graduation</th>
                                                                <th scope="col">Action</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let p of PhdEducation ; let i= index;">
                                                                <td>&nbsp;&nbsp;&nbsp;&nbsp;{{i+1}}</td>
                                                                <td> {{p.name}} </td>

                                                                <td>
                                                                    <i class="fa fa-trash"
                                                                        (click)="deletephdEducation(p.name)"></i>
                                                                </td>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6" style="margin-top:2% ;">
                                            <!-- <button type="reset" (click)="resetForm()"
                                            class="btn btn-secondary waves-effect  border border-white btn-round px-4">Reset</button> -->
                                            <!-- <button type="submit" class="btn btn-primary border border-white btn-round px-4"
                                            (click)="rezorpay()" [disabled]="submitted">Pay Now</button> -->
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6"
                                            style="margin-top:2% ; text-align: end;">
                                            <button type="submit"
                                                class="btn btn-primary border border-white btn-round px-4"
                                                (click)="rezorpay2()"
                                                [disabled]="addjobpackageForm.invalid   || !Education.length || !functionData.length || !selected.length">Pay
                                                Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="my-overlay" *ngIf="showSpinner">
    <div class="cusloader"></div>
    <h5 style="color: white;">Please Wait...</h5>
</div>