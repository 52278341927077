<div class="navbar-area fixed-top">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/logo.png" alt="Logo" style="width: 260px" /></a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                
                <div>
                    <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="Logo" /></a>
                </div>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a *ngIf="langauge_id==1" [routerLink]="['/']" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">Home</a>
                            <a *ngIf="langauge_id==2 || langauge_id==5 " [routerLink]="['/']" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">गृह</a>
                            <a *ngIf="langauge_id==3" [routerLink]="['/']" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">الرئيسية</a>
                            <a *ngIf="langauge_id==4" [routerLink]="['/']" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">Unang Pahina</a>
                        </li>
                        <li class="nav-item">
                            <a *ngIf="langauge_id==1" [routerLink]="['/about'] " class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">About Us</a>
                            <a *ngIf="langauge_id==2 || langauge_id==5" [routerLink]="['/about'] " class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">हाम्रो बारेमा</a>
                            <a *ngIf="langauge_id==3" [routerLink]="['/about'] " class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">عن الشركة</a>
                            <a *ngIf="langauge_id==4" [routerLink]="['/about'] " class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Tungkol sa
                                Amin</a>
                        </li>

                        <li class="nav-item" *ngIf="role == 0">
                            <a *ngIf="langauge_id==1" [routerLink]="['/find-a-job']" class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Find A Job</a>
                            <a *ngIf="langauge_id==2 || langauge_id==5" [routerLink]="['/find-a-job']" class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Find A Job</a>
                            <a *ngIf="langauge_id==3" [routerLink]="['/find-a-job']" class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Find A Job</a>
                        </li>

                        <li class="nav-item" *ngIf="role == 1">
                            <a *ngIf="langauge_id==1" [routerLink]="['/post-a-job']" class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Post A Job</a>
                            <a *ngIf="langauge_id==2 || langauge_id==5" [routerLink]="['/post-a-job']" class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Post A Job</a>
                            <a *ngIf="langauge_id==3" [routerLink]="['/post-a-job']" class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Post A Job</a>
                        </li>

                        <li class="nav-item">
                            <a *ngIf="langauge_id==1" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }" style="cursor: pointer;">Services</a>
                            <a *ngIf="langauge_id==2 || langauge_id==5" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }" style="cursor: pointer;">सेवा</a>
                            <a *ngIf="langauge_id==3" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }" style="cursor: pointer;">الخدمات</a>
                            <a *ngIf="langauge_id==4" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }" style="cursor: pointer;"> Mga Serbisyo</a>

                            <ul class="dropdown-menu">

                                <li class="nav-item">
                                    <a *ngIf="langauge_id==1" [routerLink]="['/service']"
                                        [queryParams]="{id: 1, name:'Personality Development Training'}"
                                        class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{ exact: true }">Personality development Training</a>
                                    <a *ngIf="langauge_id==2 || langauge_id==5" [routerLink]="['/service']"
                                        [queryParams]="{id: 1, name:'Personality Development Training'}"
                                        class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{ exact: true }">Personality development Training</a>
                                    <a *ngIf="langauge_id==3" [routerLink]="['/service']"
                                        [queryParams]="{id: 1, name:'Personality Development Training'}"
                                        class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{ exact: true }">Personality development Training</a>
                                    <a *ngIf="langauge_id==4" [routerLink]="['/service']"
                                        [queryParams]="{id: 1, name:'Personality Development Training'}"
                                        class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{ exact: true }">Pagsasanay sa Pagpapaunlad ng
                                        Pagkatao</a>
                                </li>

                                <li class="nav-item">
                                    <a *ngIf="langauge_id==1" [routerLink]="['/service']"
                                        [queryParams]="{id: 2, name:'English Communication Classes'}" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">English
                                        Communication Classes</a>
                                    <a *ngIf="langauge_id==2 || langauge_id==5" [routerLink]="['/service']"
                                        [queryParams]="{id: 2, name:'English Communication Classes'}" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">English
                                        Communication Classes</a>
                                    <a *ngIf="langauge_id==3" [routerLink]="['/service']"
                                        [queryParams]="{id: 2, name:'English Communication Classes'}" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">English
                                        Communication Classes</a>
                                    <a *ngIf="langauge_id==3" [routerLink]="['/service']"
                                        [queryParams]="{id: 2, name:'English Communication Classes'}" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Pagsasanay
                                        sa Komunikasyon sa Ingles</a>
                                </li>

                                <li class="nav-item">
                                    <a *ngIf="langauge_id==1" [routerLink]="['/service']"
                                        [queryParams]="{id: 3, name:'Fire & Safety Training'}" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Fire &
                                        Safety Training</a>
                                    <a *ngIf="langauge_id==2 || langauge_id==5" [routerLink]="['/service']"
                                        [queryParams]="{id: 3, name:'Fire & Safety Training'}" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Fire &
                                        Safety Training</a>
                                    <a *ngIf="langauge_id==3" [routerLink]="['/service']"
                                        [queryParams]="{id: 3, name:'Fire & Safety Training'}" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Pagsasanay
                                        sa Sunog at Kaligtasan</a>
                                    <a *ngIf="langauge_id==4" [routerLink]="['/service']"
                                        [queryParams]="{id: 3, name:'Fire & Safety Training'}" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Fire &
                                        Safety Training</a>
                                </li>

                                <li class="nav-item">
                                    <a *ngIf="langauge_id==1" [routerLink]="['/service']"
                                        [queryParams]="{id: 4, name:'Resume writing'}" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Resume
                                        Writing</a>
                                    <a *ngIf="langauge_id==2 || langauge_id==5" [routerLink]="['/service']"
                                        [queryParams]="{id: 4, name:'Resume writing'}" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Resume
                                        Writing</a>
                                    <a *ngIf="langauge_id==3" [routerLink]="['/service']"
                                        [queryParams]="{id: 4, name:'Resume writing'}" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Resume
                                        Writing</a>
                                    <a *ngIf="langauge_id==4" [routerLink]="['/service']"
                                        [queryParams]="{id: 4, name:'Resume writing'}" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Pagsusulat
                                        ng Resume</a>
                                </li>

                                <li class="nav-item">
                                    <a *ngIf="langauge_id==1" [routerLink]="['/service']"
                                        [queryParams]="{id: 5, name:'Mock Interview sessions'}" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Mock
                                        Interview sessions</a>
                                    <a *ngIf="langauge_id==2 || langauge_id==5" [routerLink]="['/service']"
                                        [queryParams]="{id: 5, name:'Mock Interview sessions'}" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Mock
                                        Interview sessions</a>
                                    <a *ngIf="langauge_id==3" [routerLink]="['/service']"
                                        [queryParams]="{id: 5, name:'Mock Interview sessions'}" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Mock
                                        Interview sessions</a>
                                    <a *ngIf="langauge_id==4" [routerLink]="['/service']"
                                        [queryParams]="{id: 5, name:'Mock Interview sessions'}" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Kunwaring
                                        Session ng Panayam</a>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a *ngIf="langauge_id==1" [routerLink]="['/contact']" class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Contact Us
                                &nbsp;&nbsp;</a>
                            <a *ngIf="langauge_id==2 || langauge_id==5" [routerLink]="['/contact']" class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">हामीलाई सम्पर्क
                                गर्नुहोस् &nbsp;&nbsp;</a>
                            <a *ngIf="langauge_id==3" [routerLink]="['/contact']" class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">اتصل بنا
                                &nbsp;&nbsp;</a>
                            <a *ngIf="langauge_id==4" [routerLink]="['/contact']" class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Makipag-ugnayan sa
                                Amin
                                &nbsp;&nbsp;</a>
                        </li>
                        <li class="nav-item" *ngIf="loginStatus">
                            <a *ngIf="langauge_id==1" [routerLink]="['/profile']" class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Profile
                                &nbsp;&nbsp;</a>
                            <a *ngIf="langauge_id==2 || langauge_id==5" [routerLink]="['/profile']" class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">प्रोफ़ाइल
                                &nbsp;&nbsp;</a>
                            <a *ngIf="langauge_id==3" [routerLink]="['/profile']" class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">حساب تعريفي
                                &nbsp;&nbsp;</a>
                            <a *ngIf="langauge_id==4" [routerLink]="['/profile']" class="nav-link"
                                routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">anyo sa tagiliran
                                &nbsp;&nbsp;</a>
                        </li>

                        <li class="nav-item">
                            <ng-select (change)="changelangauge($event)" [(ngModel)]="langauge">
                                <ng-option value="">Language</ng-option>
                                <ng-option value="1">English</ng-option>
                                <ng-option value="2">Nepal</ng-option>
                                <ng-option value="3">Arabic</ng-option>
                                <ng-option value="5">Hindi</ng-option>
                                <ng-option value="4">Filipino</ng-option>
                            </ng-select>
                        </li>
                    </ul>

                    <div class="common-btn" *ngIf="!loginStatus">
                        <a *ngIf="langauge_id==1" class="login-btn curse" style="width: 165px;" routerLink="/login"><i
                                class="icofont-plus-square"></i> Job Seeker Login </a>
                        <a *ngIf="langauge_id==2 || langauge_id==5" class="login-btn curse" style="width: 165px;" routerLink="/login"><i
                                class="icofont-plus-square"></i> नौकरी साधक लगइन </a>
                        <a *ngIf="langauge_id==3" class="login-btn curse" style="width: 165px;" routerLink="/login"><i
                                class="icofont-plus-square"></i> تسجيل دخول الباحث عن عمل </a>

                        <a *ngIf="langauge_id==4" class="login-btn curse" style="width: 165px;" routerLink="/login"><i
                                class="icofont-plus-square"></i> Log in ng naghahanap ng trabaho </a>
                    </div>

                    <div class="common-btn" *ngIf="!loginStatus">
                        <a *ngIf="langauge_id==1" class="login-btn curse" style="width: 165px;" appHost
                            [routerLink]="['/create-account']"><i class="icofont-user-alt-4"></i>
                            Employer Login
                        </a>
                        <a *ngIf="langauge_id==2 || langauge_id==5" class="login-btn curse" style="width: 165px;" appHost
                            [routerLink]="['/create-account']"><i class="icofont-user-alt-4"></i>
                            नियोक्ताको लगइन
                        </a>
                        <a *ngIf="langauge_id==3" class="login-btn curse" style="width: 165px;" appHost
                            [routerLink]="['/create-account']"><i class="icofont-user-alt-4"></i>
                            تسجيل دخول صاحب العمل
                        </a>
                        <a *ngIf="langauge_id==4" class="login-btn curse" style="width: 165px;" appHost
                            [routerLink]="['/create-account']"><i class="icofont-user-alt-4"></i>Mag-log In ang Employer
                        </a>
                    </div>

                    <div class="common-btn" *ngIf="loginStatus">
                        <a *ngIf="langauge_id==1" class="sign-up-btn curse" (click)="logoutAlert()"><i
                                class="icofont-user-alt-4"></i> Logout</a>
                        <a *ngIf="langauge_id==2 || langauge_id==5" class="sign-up-btn curse" (click)="logoutAlert()"><i
                                class="icofont-user-alt-4"></i> लॉग आउट</a>
                        <a *ngIf="langauge_id==3" class="sign-up-btn curse" (click)="logoutAlert()"><i
                                class="icofont-user-alt-4"></i>تسجيل الخروج</a>
                        <a *ngIf="langauge_id==4" class="sign-up-btn curse" (click)="logoutAlert()"><i
                                class="icofont-user-alt-4"></i>
                            mag log out </a>

                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>