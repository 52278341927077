import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  profilearr: any;
  paymentarr: any;
  role: any;
  studentdata: any[] = [];
  getexamtest: any[] = [];
  studentform: FormGroup
  recruiterSignup: FormGroup
  constructor(private profileservice: ServiceService, private router: Router, private formBuilder: FormBuilder) {
    this.getStudentProfileData();
    this.role = localStorage.getItem('role');
    this.getExamTypeList();
  }

  ngOnInit(): void {
    this.getloginprofile();
    this.getpaymentprofile();


    this.studentform = this.formBuilder.group({
      name: [''],
      email: [''],
      date_of_birth: [''],
      phone_number: [''],
      job_title: [''],
      address: [''],
      gender: [''],
      qualification: [''],
      year_of_passing: [''],
      percentage: [''],
      experience: ['']
    });

    this.recruiterSignup = this.formBuilder.group({
      recruiter_name: [''],
      recruiter_email: [''],
      phone: [''],
      company_name: [''],
      recruiter_address: [''],
      industry_type: [''],
      recruiter_description: [''],
    });

  }

  get formControls() {
    return this.studentform.controls;
  }

  loginDatastudent: any

  onSubmit() {
    if (this.studentform.valid) {
      this.studentform.value.id = this.studentdata[0].id;
      this.profileservice.upadtestudentdetails(this.studentform.value).subscribe((res: any) => {
        if (res.status == 200) {
          Swal.fire('Updated Successfully');
          this.loginDatastudent = res.data;
          localStorage.setItem('currentUser', JSON.stringify(this.loginDatastudent));
          localStorage.setItem('user_id', res.usr_data[0].id);
          localStorage.setItem('role', '0');
          localStorage.setItem('student_id', res.usr_data[0].id);
          localStorage.setItem('date', res.usr_data[0].i_ts);
          this.getStudentProfileData();
        }
      })
    } else {
      
    }
  }
  loginData:any

  upadteemployee() {
    if (this.recruiterSignup.valid) {
      this.recruiterSignup.value.id = this.profilearr[0].id;
      this.profileservice.upadteemployeedetails(this.recruiterSignup.value).subscribe((res: any) => {
        if (res.status == 200) {
          Swal.fire('Updated Successfully');
          this.loginData = res.data;
          localStorage.setItem('currentUser', JSON.stringify(this.loginData));
          localStorage.setItem('user_id', res.usr_data[0].id);
          localStorage.setItem('role', '1');
          localStorage.setItem('companyuser_id', res.usr_data[0].id);
          localStorage.setItem('newdata', JSON.stringify(this.loginData))
        }
      })
    } else {
      
    }
  }



  getExamTypeList() {
    var user_id = localStorage.getItem('user_id')
    this.profileservice.ExamTypeList(user_id).subscribe((Result: any) => {
      if (Result.status == 200) {
        this.getexamtest = Result.data.filter(res => {
          return res.completed == 1;
        })
        this.getexamtest.map(poco => {
          poco.result = Math.round((poco.score / poco.total_marks) * 100)
        })
        this.getexamtest.sort((a: any, b: any) => b.result - a.result);
        
      }
    })
  }

  getStudentProfileData() {
    var id = localStorage.getItem('user_id')
    this.profileservice.getStudentProfileData(id).subscribe(data => {
      this.studentdata = data.data;
      
      this.studentform.patchValue({
        name: this.studentdata[0].name,
        email: this.studentdata[0].email,
        date_of_birth: this.studentdata[0].date_of_birth,
        phone_number: this.studentdata[0].phone_number,
        job_title: this.studentdata[0].job_title,
        address: this.studentdata[0].address,
        gender: this.studentdata[0].gender,
        qualification: this.studentdata[0].qualification,
        year_of_passing: this.studentdata[0].year_of_passing,
        percentage: this.studentdata[0].percentage,
        experience: this.studentdata[0].experiance,
      });

    }, er => {
      
    })
  }

  getloginprofile() {
    var data = {
      'companyuser_id': localStorage.getItem('companyuser_id'),
    }
    this.profileservice.getloginprofiledata(data).subscribe(data => {
      if (data.status == 200) {
        this.profilearr = data.data;
        

        this.recruiterSignup = this.formBuilder.group({
          recruiter_name: this.profilearr[0].recruiter_name,
          recruiter_email: this.profilearr[0].recruiter_email,
          phone: this.profilearr[0].phone_number,
          company_name: this.profilearr[0].company_name,
          recruiter_address: this.profilearr[0].address,
          industry_type: this.profilearr[0].industry_type,
          recruiter_description: this.profilearr[0].description,
        });
      }
    }, er => {
      
    })

  }

  getpaymentprofile() {
    var data = {
      'companyuser_id': localStorage.getItem('companyuser_id'),
    }
    this.profileservice.getpaymentprofiledata(data).subscribe(data => {
      if (data.status == 200) {
        this.paymentarr = data.data;
      }
    }, er => {
      
    })

  }
  startTest(id, limt, time) {
    if (localStorage.getItem('role') == '0') {
      this.router.navigate(['/blog-details', { id: id, limit: limt, it: time }]);
    } else {
      this.router.navigate(['/login', { index: 1 }]);
    }

  }

  genarateCert(date, exam) {
    var data = {
      date: date,
      exam: exam,
      name: this.studentdata[0].name

    }
  

    sessionStorage.setItem('certdata', JSON.stringify([data]))
    this.router.navigate(['/cert'])
  }

  showin: any = ''
  openmodal(id) {
    if (this.showin == '') {
      this.showin = id
    } else {
      this.showin = '';
    }

  }

  closestudentfrom() {
    this.showin = '';
  }


}
