import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  websitecount: any;

  constructor(private serv: ServiceService) { }

  langauge_id:any

  ngOnInit(): void {
    this.getwebsitecount();
    this.langauge_id = localStorage.getItem('language_id')
  }


  getwebsitecount() {
    this.serv.getwebsitecount().subscribe(res => {
      
      this.websitecount = res.data
      res.data.map((res, index) => { res.i = ++index; })
    }, error => {
    
    })
  }
}
