import { Directive, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[appHost]'
})
export class HostDirective {

  constructor(private router: Router) { }



}
