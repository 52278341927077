import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoginModel } from '../model/login.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';


// var LoginApi = 'http://92.204.139.190:3874/nodeapp/'
//  var LoginApi: string = 'http://aromacares.in:2302/nodeapp/'
// var Api = 'http://localhost:8083/nodeapp/';
var Api='https://recruitin.in:8084/nodeapp/';
//  var  LoginApi  = environment;

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private currentUserSubject: BehaviorSubject<LoginModel>;   // current subject by default
  public currentUser: Observable<LoginModel>;        // Emmiting value when changes occures
  loginData: LoginModel;                    // Login Model Data

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<LoginModel>(JSON.parse(sessionStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): LoginModel {
    return this.currentUserSubject.value;
  }

  // Get Otp for user dashboard //
  getdashotp(phone) {
    return this.http.post<any>(Api + 'getdashotp', phone)
  }

  // Login method for submitting login form data value

  login(signInData: LoginModel) {
   

    return this.http.post<any>(Api + 'getUserData', signInData)
      .pipe(map(user => {
        
        if (user.status == 202) {
          this.errorMessageAlert('Please Enter Valid OTP')
        }

        if (!user.data.length) {
          this.errorMessageAlert("You don’t have any permissions please contact Admin ");
          return user.data;
        }

        if (user.status == 200) {
          this.loginData = user.data;
          sessionStorage.setItem('currentUser', JSON.stringify(this.loginData));
          sessionStorage.setItem('user_id', user.usr_data[0].id);
          this.currentUserSubject.next(this.loginData);
         

          return this.loginData;
        }


      }));
  }



  errorMessageAlert(message) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
      showCloseButton: true,
      showCancelButton: false,
      showConfirmButton: true,
      timer: 1500
    })
  }


  // logout function when user want to logout

  logout() {
    // remove user from local storage to log user out
    sessionStorage.removeItem('currentUser');
    sessionStorage.clear();

    localStorage.removeItem('currentUser');
    localStorage.clear();
    this.currentUserSubject.next(null);
    this.router.navigate(['/']);
    window.location.reload();
  }
}
