<div class="page-title-area-about">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text noob" *ngIf="langauge_id==1">
                    <h2>About - Us</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>About - Us</li>
                    </ul>
                </div>
                <div class="page-title-text noob" *ngIf="langauge_id==5">
                    <h2>हमारे बारे में</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>हमारे बारे में</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="portal-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="portal-item">
                    <div class="row">
                        <div class="col-lg-7">
                            <img src="assets/img/home-1/1.png" alt="Portal">
                        </div>

                        <div class="col-lg-5">
                            <img src="assets/img/home-1/2.png" alt="Portal">
                        </div>
                    </div>

                    <div class="portal-trusted">
                        <span>100% Trusted</span>
                    </div>
                </div>
            </div>


            <div class="col-lg-6" *ngIf="langauge_id==5">
                <div class="portal-item portal-right portal-right-two">
                    <h2>
                        विश्वसनीय और लोकप्रिय जॉब पोर्टल</h2>
                    <p class="justy" style="text-align: justify;">
                        रिक्रूटिन आवेदकों और नियोक्ताओं के लिए एक वैश्विक ऑनलाइन रोजगार समाधान है, जिन्हें अच्छे लोगों
                        की आवश्यकता है। हम वर्षों से ऐसा कर रहे हैं, और एक "नौकरी बोर्ड" के रूप में अपने मूल से नौकरी की
                        तलाश, कैरियर प्रबंधन, भर्ती और प्रतिभा प्रबंधन उत्पादों और सेवाओं की एक पूरी श्रृंखला के वैश्विक
                        प्रदाता के रूप में विस्तारित . </p>
                    <p class="justy" style="text-align: justify;">हम नौकरी के बारे में लोगों की सोच को बदल रहे हैं, और
                        हम उन्हें नई तकनीक, उपकरणों और प्रथाओं के साथ अपने जीवन और अपने कार्यबल के प्रदर्शन को सक्रिय
                        रूप से बेहतर बनाने में मदद कर रहे हैं।</p>
                </div>
            </div>

            <div class="col-lg-6" *ngIf="langauge_id==1">
                <div class="portal-item portal-right portal-right-two">
                    <h2>Trusted & Popular Job Portal</h2>
                    <p class="justy" style="text-align: justify;">Recruitin is a global online employment solution for
                        people seeking jobs and the employers who
                        need great people. We've been doing this since years, and have expanded from our roots as a "job
                        board" to a global provider of a full array of job seeking, career management, recruitment and
                        talent
                        management products and services.</p>
                    <p class="justy" style="text-align: justify;">We are changing the way people think about work, and
                        we're helping them actively improve their
                        lives and their workforce performance with new technology, tools and practices.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="counter-area pt-100 pb-70" *ngIf="langauge_id==1">
    <div class="container">
        <div class="row" *ngFor="let p of websitecount">
            <div class="col-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-employee"></i>
                    <h3 class="hell"><span class="counter">{{p.available_jobs}}</span>+</h3>
                    <p>Job Available</p>
                </div>
            </div>
            <div class="col-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-curriculum"></i>
                    <h3 class="hell"><span class="counter">{{p.cv_submitted}}</span>+</h3>
                    <p>Profiles</p>
                </div>
            </div>
            <div class="col-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-enterprise"></i>
                    <h3 class="hell"><span class="counter">{{p.total_companies}}</span>+</h3>
                    <p>Companies</p>
                </div>
            </div>
            <div class="col-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-businessman-paper-of-the-application-for-a-job"></i>
                    <h3 class="hell"><span class="counter">{{p.appoitment_jobs }}</span>+</h3>
                    <p>Placed In jobs</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="counter-area pt-100 pb-70" *ngIf="langauge_id==5">
    <div class="container">
        <div class="row" *ngFor="let p of websitecount">
            <div class="col-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-employee"></i>
                    <h3 class="hell"><span class="counter">{{p.available_jobs}}</span>+</h3>
                    <p> उपलब्ध नौकरियाँ</p>
                </div>
            </div>
            <div class="col-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-curriculum"></i>
                    <h3 class="hell"><span class="counter">{{p.cv_submitted}}</span>+</h3>
                    <p>प्रोफाइल</p>
                </div>
            </div>
            <div class="col-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-enterprise"></i>
                    <h3 class="hell"><span class="counter">{{p.total_companies}}</span>+</h3>
                    <p>कंपनियाँ</p>
                </div>
            </div>
            <div class="col-6 col-sm-3 col-lg-3">
                <div class="counter-item">
                    <i class="flaticon-businessman-paper-of-the-application-for-a-job"></i>
                    <h3 class="hell"><span class="counter">{{p.appoitment_jobs }}</span>+</h3>
                    <p>नौकरियों में नियुक्त</p>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="popular-area pt-100 pb-17">
    <div class="container">
        <div class="row align-items-center">


            <div class="col-lg-6">
                <div class="popular-item popular-right">
                    <div class="section-title text-start">
                        <h2>VISION, MISSION & VALUES</h2>
                    </div>
                    <h4><a class="line">VISION</a></h4>
                    <p class="justy" style="color: #033333;font-weight: 400;">
                        Sustainable Employment within a Healthy Community .
                    </p>

                    <h4><a class="line">MISSION</a></h4>
                    <p class="justy" style="color: #033333;font-weight: 400;">
                        Recruitin assists job seekers and
                        employers to achieve employment goals. The agency works with
                        community partners to respond to emerging needs and opportunities with creativity and
                        professionalism.
                    </p>

                    <h4><a class="line">VALUES</a></h4>
                    <ul class="a" style="list-style: square;">
                        <li class="list">Respect for Self and Others</li>
                        <li class="list">Professionalism</li>
                        <li class="list">Model Workplace</li>
                        <li class="list">Community Participation</li>
                        <li class="list">Future Focused</li>
                    </ul>

                    <h4 style="margin-top: 25px;"><a class="line">GUIDING PRINCIPLES</a></h4>
                    <ul class="a" style="list-style: square;">
                        <li class="list">Embrace diversity</li>
                        <li class="list">Respect confidentiality</li>
                        <li class="list">Support life-long learning</li>
                        <li class="list">Strengthen self-sufficiency</li>
                        <li class="list">Promote exemplary employment practices</li>
                        <li class="list">Seek progressive solutions for positive social change</li>
                        <li class="list">Work collaboratively to build community capacity</li>
                        <li class="list">Maintain a competitive edge</li>
                    </ul>


                    <!-- <p class="justy">Recruitin web application that will be helpful to manage the process for the hiring process. Recruitment and selection is a very important process to select the best candidates. This guide sets out best practice guidelines to assist you in making this process as effective as possible. guide all employees involved in the recruitment and selection of employees.</p> -->

                    <div class="row popular-wrap" style="margin-top: 30px;">
                        <div class="col-sm-6 col-lg-6">
                            <ul>
                                <li><i class="flaticon-approved"></i> Trusted & Quality Job</li>
                                <li><i class="flaticon-no-money"></i> No Extra Charge</li>
                            </ul>
                        </div>

                        <div class="col-sm-6 col-lg-6">
                            <ul>
                                <li><i class="flaticon-enterprise"></i> Top Companies</li>
                                <li><i class="flaticon-employee"></i> International Job</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="popular-item">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <img src="assets/img/home-1/3.png" alt="Popular">
                        </div>

                        <div class="col-lg-5">
                            <div class="practice-inner">
                                <img src="assets/img/home-1/4.png" alt="Popular">
                                <img src="assets/img/home-1/5.png" alt="Popular">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>