import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { ServiceService } from '../service.service';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  dynoclass: boolean = true;
  contactUs: FormGroup;
  initialValues;
  submitformres: boolean;
  submitted: boolean = false;
  constructor(private form: FormBuilder, private serv: ServiceService) {
    this.contactUs = this.form.group({
      fullname: ["", Validators.required],
      email: ["", Validators.compose([Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')])],
      phone_number: ["", [Validators.required, Validators.minLength(10)]],
      //   msg_subject: ["", Validators.required],
      message: ["", Validators.required],
    });
    this.initialValues = this.contactUs;
  }
  ngOnInit(): void { }
  get formdata() {
    return this.contactUs.controls;
  }

  contactPostSubmit() {
    this.submitted = true;
    if (this.contactUs.invalid) {
      return;
    }
    
    this.serv.contactUspost(this.contactUs.value).subscribe(
      res => {
        if (res.status == 200) {
          this.contactUs.reset(this.initialValues);
    
          this.sucessAlert('Data Submitted Sucessfully ');

        }

      })
  }
  sucessAlert(message) {
    Swal.fire({
      icon: 'success',
      title: message,
      showConfirmButton: false,
      timer: 1500
    })
  }
  //validations
  get addUsersFormCnt() { return this.contactUs.controls; }


  //validations
  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }
  resetForm() {
    this.submitted = false;
  }

  //validations

}
