<div class="page-title-area-jobs">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-text noob">
                    <h2>Post A Job</h2>
                    <ul>
                        <li><a routerLink="/">Companies</a></li>
                        <li><i class="icofont-simple-right"></i></li>
                        <li>Post A Job</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div style="font-family:Times New Roman;">
    <section class="faq-area pt-100">
        <div class="container">
            <div class="row faq-wrap">
                <div class="col-lg-12">
                    <div class="faq-head">
                        <h2 style="text-align:center">Quick & Easy Job Posting − Get Quality Applies</h2>
                    </div>
                    <div class="pricing-area">
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-6 col-lg-4" *ngFor="let i of packages">
                                    <div class="pricing-item wow fadeInUp" data-wow-delay=".3s">
                                        <span style="font-size:32px ;">{{i.packagename}}
                                        </span><br>
                                        <h2>₹{{i.packageamount}}</h2>
                                        <p style="font-size:20px ;">{{i.uploadresume}} Resumes</p>
                                        <ul>
                                            <li style="font-size:20px ;">
                                                {{i.description}}</li>
                                        </ul>
                                        <br>
                                        <div style="margin-bottom: 3%;">
                                            <button type="button" class="btn btn-primary" (click)="submitbuynow(i)">Buy
                                                Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="margin-bottom: 8%;">
                        <h3 style="text-align:center;" class="head">Why Choose Recruitin Job Posting?</h3>
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="pricing-item pricing-two wow fadeInUp" data-wow-delay=".4s">
                                    <div class="job-card" style="color: #b3b9ba;">
                                        <div class="job-inner align-items-center">
                                            <img src="assets/img/icon1.png" style="height: 64px; width:65px;">
                                            <h4>2 Minutes To Post</h4>
                                            <h5>Quick and easy to post job posting with highly optimised job
                                                posting form</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="pricing-item pricing-two wow fadeInUp" data-wow-delay=".4s">
                                    <div class="job-card">
                                        <div class="job-inner align-items-center">
                                            <img src="assets/img/icon2.png" style="height: 64px; width:65px;">
                                            <i class="fa-brands fa-searchengin"></i>
                                            <h4>Attract Audience</h4>
                                            <h5>Reach to over 8.27 crore talented jobseekers</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="pricing-item pricing-two wow fadeInUp" data-wow-delay=".4s">
                                    <div class="job-card">
                                        <div class="job-inner align-items-center">
                                            <img src="assets/img/icon3.png" style="height: 64px; width:65px;">
                                            <h4>30 days visibility</h4>
                                            <h5>Get quality applies guaranteed with 30 days visibility of your job
                                                ads.</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>