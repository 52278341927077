import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  getexamtest:any;
  constructor(private serv:ServiceService, private router: Router) {
    this.getExamTypeList();
   }

  ngOnInit(): void {

  }
  getExamTypeList() {
    var user_id = localStorage.getItem('user_id')
    this.serv.ExamTypeList(user_id).subscribe((Result: any) => {
      if (Result.status == 200) {
        this.getexamtest = Result.data;
        
      }
    })
  }

  startTestDone(id, limt, time, ex_name, ex_cate){
    if (localStorage.getItem('role') == '0') {
        this.router.navigate(['/blog-details', {id :id, limit: limt, it:time, ex_name:ex_name, ex_cate:ex_cate }]);
    } else {
        this.router.navigate(['/login', {index: 1}]);
    }

  }

  startTest(id, limt, time, ex_name, ex_cate){
    Swal.fire({
      title: 'Do You Want to Start Test',
      text: "Once you start the test timer will start",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Do it!'
    }).then((result) => {
      if (result.isConfirmed) {
       this.startTestDone(id, limt, time, ex_name, ex_cate);
      }
    })
  }
}
