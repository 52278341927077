import { Component, OnInit } from '@angular/core';

import { SerService } from 'src/app/components/pages/feq/ser.service';
import { Router, ActivatedRoute } from "@angular/router";
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  packages: any;
  companyuser_id;
  packagedatails:any;
  constructor(private service : SerService,private router: Router) {

  }

  ngOnInit(): void {
    this.companyuser_id = localStorage.getItem('companyuser_id');

    this.getpackagedata();
  }
  getpackagedata() {
   
    this.service.getpackagedatalist().subscribe(data => {
      if (data.status == 200) {
        this.packages = data.data;

       
      }
    }, er => {
     
    })

  }

  submitbuynow(packagedatails){
    localStorage.setItem('newpackage', JSON.stringify(packagedatails));
   

    if(localStorage.getItem("role") == "0"  || localStorage.getItem("companyuser_id") == "0" || localStorage.getItem("companyuser_id") == undefined ||  localStorage.getItem("companyuser_id") == null || localStorage.getItem("companyuser_id") == "")
    {
      
      this.router.navigate(['/login', {index: 2}]);
    }
    else{
      this.router.navigate(['/packages']);
    }
  }
}

